import { FunctionalComponent, h, render } from "preact";
import { VoiceShoppingCart } from "./Components/shopping-cart";
import { ShoppingCartNotification } from "./Components/shopping-cart-notification";
import { ShoppingCartStore } from "./shop-cart.store";
import { CookieStorage } from "@shared/cookieStorage";
import register from "preact-custom-element";
import { AddOfferingButton } from "./Components/add-offering-button";
import { ShoppingCartStoreOptions, voiceShopStoreStateType } from "./shop-types";
import { IceAnimationHeightProvider } from "../../Shared/Components/IceAnimation/IceAnimationHeightProvider";

type ShoppingCartAppProps = { useVoiceStore: voiceShopStoreStateType; shoppingCartRootEl: HTMLElement };
const shoppingcartApp: FunctionalComponent<ShoppingCartAppProps> = ({
  useVoiceStore,
  shoppingCartRootEl,
}: ShoppingCartAppProps) => {
  return (
    <IceAnimationHeightProvider>
      <VoiceShoppingCart useVoiceStore={useVoiceStore} shoppingCartRootEl={shoppingCartRootEl} />
    </IceAnimationHeightProvider>
  );
};

export const LoadShoppingCart = (): void => {
  const shoppingCartRootEl = document.querySelector<HTMLElement>("#ShoppingCartApp");
  if (!shoppingCartRootEl) return;

  const domain = `.${window.location.host.split(".").slice(1).join(".")}`;
  const cookieJar = new CookieStorage(window.document, 1000 * 60 * 30, domain);
  const shoppingCartContainer = document.querySelector<HTMLElement>("#shoppingCartBackDrop");
  const notificationContainer = document.querySelector<HTMLElement>("#shoppingCartNotification");

  const namespace = shoppingCartRootEl.dataset.isMbb === "True" ? "IceMbbShoppingCart" : "IceShoppingCart";

  const storeParams: ShoppingCartStoreOptions = {
    cookieJar: cookieJar,
    namespace: namespace,
    isBusiness: false,
    isMBB: false,
    maxOrders: 5,
    shippingPriceMbb: 0,
  };

  const mbbSettings = document.querySelector(".mbb-overview-settings");
  if (mbbSettings) {
    storeParams.isMBB = true;
    storeParams.isBusiness = mbbSettings.getAttribute("is-inside-business") === "True";

    const maxBundle = parseInt(mbbSettings.getAttribute("max-order-bundle"), 10);
    storeParams.maxOrders = Number.isNaN(maxBundle) || maxBundle < 1 ? 5 : maxBundle;

    const shippingPrice = parseInt(mbbSettings.getAttribute("shipping-price"), 10);
    storeParams.shippingPriceMbb = Number.isNaN(shippingPrice) ? 99 : shippingPrice;
  }

  const useVoiceStore = ShoppingCartStore(storeParams);
  render(h(shoppingcartApp, { useVoiceStore, shoppingCartRootEl }), shoppingCartContainer);
  render(h(ShoppingCartNotification, undefined), notificationContainer);
};

register(
  AddOfferingButton(),
  "ice-addoffering-button",
  [
    "buttontext",
    "planid",
    "blockid",
    "classname",
    "buttonclassname",
    "ariabuttonlabel",
    "dataid",
    "productname",
    "productpage",
  ],
  {
    shadow: false,
  },
);

LoadShoppingCart();
