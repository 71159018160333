import { h, FunctionalComponent } from "preact";
import { CloseIcon } from "@shared/Components";

export interface DropDownTitleProps {
  closeShoppingCart: () => void;
  onKeyDownHandler: (e: KeyboardEvent) => void;
  productCount: number;
}

export const DropDownTitleView: FunctionalComponent<DropDownTitleProps> = ({
  closeShoppingCart,
  onKeyDownHandler,
  productCount,
}: DropDownTitleProps) => {
  return (
    <div className="shopping-cart__head">
      <div id="shopping-cart-heading" className="shopping-cart__title" aria-hidden="true">
        Din handlekurv {productCount > 0 ? `(${productCount})` : ""}
      </div>
      <span
        aria-label={
          productCount > 0 ? `Din handlekurv med ${productCount} produkter` : "Din handlekurv, ingen produkter valgt"
        }
      ></span>
      <button
        aria-label="Lukk handlekurv"
        className="shopping-cart__close"
        onClick={closeShoppingCart}
        onKeyDown={onKeyDownHandler}
      >
        <CloseIcon />
      </button>
    </div>
  );
};
