import type { ProductBlockDataModel } from "./subscription-builder.types";

export const fetchProductBlockDataService = (blockId: string): Promise<ProductBlockDataModel> => {
  return fetch(`/api/subscriptionbuilder/GetSubscriptionBlock?blockId=${blockId}`)
    .then((res) => res.json())
    .then((data: string) => {
      return JSON.parse(data) as ProductBlockDataModel;
    });
};

export const fetchProductBlocksDataServices = (blockIds: string[]): Promise<ProductBlockDataModel[]> => {
  return fetch(`/api/subscriptionbuilder/GetSubscriptionBlocks/?blockIds=${blockIds.join(",")}`)
    .then((res) => res.json())
    .then((data: string) => {
      return JSON.parse(data) as ProductBlockDataModel[];
    });
};
