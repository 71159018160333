import { pushToDataLayer } from "../../data/data-layer.facade";

export type BaseEcommerceAddonEvent = {
  event: "mobilabonnement";
  element_category: "tilleggsvelger" | "netflix";
  element_cta?: EcommerceAddonCTA;
  element_checkbox?: string;
  item_name?: string; // only used for checkbox events
};

type EcommerceAddonCTA = "open" | "close" | "proceed";

export const sendGA4AddonEvent = (cta: EcommerceAddonCTA, isExternalAddon?: boolean): void => {
  const event: BaseEcommerceAddonEvent = {
    event: "mobilabonnement",
    element_category: isExternalAddon ? "netflix" : "tilleggsvelger",
    element_cta: cta,
  };

  pushToDataLayer(event);
};
