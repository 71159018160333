import { Fragment, type FunctionalComponent, h } from "preact";
import { IceFeedbackFormSteps } from "../../Component/ice-feedback-component";
import { IceFeedbackPopupPrompt } from "./prompt";
import { IceFeedbackPopupForm, PopupFormSuccess } from "./form";
import { useState } from "preact/hooks";

interface IceFeedbackPopupContentProps {
  feedbackFormStep: IceFeedbackFormSteps;
  setFeedbackFormStep: (step: IceFeedbackFormSteps) => void;
  setUserFeedbackPositive: (userFeedbackPositive: boolean) => void;
  userFeedbackPositive: boolean | undefined;
  headerAccessibilityId: string;
}

export const IceFeedbackPopupContent: FunctionalComponent<IceFeedbackPopupContentProps> = ({
  feedbackFormStep,
  setFeedbackFormStep,
  setUserFeedbackPositive,
  userFeedbackPositive,
  headerAccessibilityId,
}: IceFeedbackPopupContentProps) => {
  const [publicId, setPublicId] = useState<string>("");

  const renderContent = () => {
    switch (feedbackFormStep) {
      case IceFeedbackFormSteps.prompt:
        return (
          <IceFeedbackPopupPrompt
            feedbackFormStep={feedbackFormStep}
            setFeedbackFormStep={setFeedbackFormStep}
            setUserFeedbackPositive={setUserFeedbackPositive}
            userFeedbackPositive={userFeedbackPositive}
            setPublicId={setPublicId}
            publicId={publicId}
            headerAccessibilityId={headerAccessibilityId}
          />
        );
      case IceFeedbackFormSteps.details:
        return (
          <IceFeedbackPopupForm
            userFeedbackPositive={userFeedbackPositive}
            feedbackFormStep={feedbackFormStep}
            setFeedbackFormStep={setFeedbackFormStep}
            publicId={publicId}
          />
        );
      case IceFeedbackFormSteps.success:
        return <PopupFormSuccess />;
      default:
        return <Fragment />;
    }
  };

  return <div className="ice-feedback__content">{renderContent()}</div>;
};
