import { FunctionalComponent, JSX, h } from "preact";
export interface ResponsiveImageProps extends JSX.HTMLAttributes<HTMLImageElement> {
  src: string;
  alt?: string;
  sizes?: string;
  quality: number | null;
}
const presets = ["1920", "1366", "1024", "768", "640", "320", "160", "80"];
export const ResponsiveImage: FunctionalComponent<ResponsiveImageProps> = ({
  src,
  alt = "",
  sizes = "100vw",
  quality,
  ...rest
}: ResponsiveImageProps) => {
  return (
    <img
      alt={alt}
      srcSet={presets
        .map((p) => {
          if (quality != null) return `${src}?width=${p}&quality=${quality} ${p}w`;
          return `${src}?width=${p} ${p}w`;
        })
        .join(", ")}
      sizes={sizes}
      {...rest}
    />
  );
};
