import { h, FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import { HelloHandIcon } from "../../Helpers/Icons";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseIcon } from "@shared/Components";

export interface MaxOfferingsDialogProps {
  setFocusAndResetState: () => void;
  isBusiness: boolean;
  maxProductCount: number;
}

export const MaxOfferingsDialog: FunctionalComponent<MaxOfferingsDialogProps> = ({
  setFocusAndResetState,
  isBusiness,
  maxProductCount,
}: MaxOfferingsDialogProps) => {
  const [openState, setOpenState] = useState(true);

  const onOpenChangeHandler = (open: boolean) => {
    setOpenState(open);

    if (!open) setFocusAndResetState();
  };

  return (
    <Dialog.Root open={openState} onOpenChange={onOpenChangeHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="radix-modal__overlay" />
        <Dialog.Content className="radix-modal__content max-offerings">
          <HelloHandIcon />
          <Dialog.DialogTitle>
            {`Hei! Du kan maks bestille ${maxProductCount} stk. ${
              isBusiness ? "Bedrifts" : "Privat"
            }-abonnement på ice.no`}
          </Dialog.DialogTitle>

          <p className="mt-2">Dersom du ønsker å bestille flere, kontakt kundeservice så hjelper vi deg!</p>
          <div className="max-offerings__kontakt text--left">
            <div className="max-offerings__phone">
              <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                <use xlinkHref="#ice-phone"></use>
              </svg>{" "}
              <span>
                Ring oss på <span className="text--bold-700">+47&nbsp;21&nbsp;00&nbsp;00&nbsp;00</span>
              </span>
            </div>
            <ul className="list--unstyled">
              <li>
                Hverdager: <span className="text--bold-700">08-20</span>
              </li>
              <li>
                Lørdager: <span className="text--bold-700">09-17</span>
              </li>
              <li>
                Søndag og helligdager: <span className="text--bold-700">Stengt</span>
              </li>
            </ul>
          </div>

          <a className="max-offerings__bedrift" href="/bedrift/mobil/abonnement">
            <span>
              <b>Bedriftskunde?</b> Klikk her
            </span>
            <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
              <use xlinkHref="#ice-arrow--forward"></use>
            </svg>
          </a>

          <Dialog.Close asChild>
            <button className="link mt-2" aria-label="Close">
              Lukk
            </button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <button className="radix-modal__close" aria-label="Close">
              <CloseIcon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
