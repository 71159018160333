import { h, type FunctionalComponent } from "preact";
import type { ProductBlockDataModel } from "../../Helpers/subscription-builder.types";
import type { voiceShopStoreStateType } from "../../shop-types";
import { DialogState } from "../shopping-cart-event-handler";
import { MaxOfferingsDialog } from "./max-offering-dialog";
import { ExternalAddserviceDialog } from "./external-addservice-dialog";

export type DialogLoaderProps = {
  dialogState: DialogState;
  productData?: ProductBlockDataModel;
  setFocusAndResetState: () => void;
  maxOrders: number;
  isBusiness: boolean;
  useVoiceStore: voiceShopStoreStateType;
  openButton?: HTMLElement;
};

export const DialogLoader: FunctionalComponent<DialogLoaderProps> = ({
  dialogState,
  productData,
  setFocusAndResetState,
  maxOrders,
  isBusiness,
  useVoiceStore,
  openButton,
}: DialogLoaderProps) => {
  if (dialogState === DialogState.none || dialogState === DialogState.default) {
    return null;
  }

  if (dialogState === DialogState.maxOfferings) {
    return (
      <MaxOfferingsDialog
        setFocusAndResetState={setFocusAndResetState}
        maxProductCount={maxOrders}
        isBusiness={isBusiness}
      />
    );
  }

  if (dialogState === DialogState.external) {
    if (!productData) {
      throw new Error("Product data not received when loading external dialog");
    }

    return (
      <ExternalAddserviceDialog
        currentServiceTitle=""
        productData={productData}
        useVoiceStore={useVoiceStore}
        closeAndSetFocus={setFocusAndResetState}
        openButton={openButton}
      />
    );
  }

  return null;
};
