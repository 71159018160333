import { ICE_FEEDBACK_EVENT_KEY } from "../../Feedback/Component/ice-feedback-component";
import { AdditionalServices, Offering, Subscription, baseMbbBundle } from "../shop-types";

export type SubscriptionIds = { planId: string; serviceIds?: string[]; blockId?: string; productPage: string };

export const getOrderFormWebCheckoutUrl = (subscriptions: SubscriptionIds[]): string => {
  const url = new URL(`${window.ICE.settings.orderFormBaseUrl}/mobil`);

  if (subscriptions?.length > 0) {
    subscriptions.forEach((subscription) => {
      url.searchParams.append(`pid-${subscription.planId}`, subscription.serviceIds?.join(",") || "");
      url.searchParams.append(`source`, subscription.productPage);
    });
  }

  if (subscriptions?.length) url.searchParams.append("addedToCart", "true");
  return url.toString().replace(/%2C/gi, ",");
};

export const getCheckoutUrlMBB = (subs: baseMbbBundle[]): string => {
  const plansIdString = subs.map((sub) => sub.planId).join("%2C");
  return `${window.ICE.settings.mbbOrderBaseUrl}${plansIdString}`;
};

export const sumActiveSubscriptionServices = (items: AdditionalServices[]): number => {
  if (!items) return 0;

  return items.reduce((sum, item) => {
    if (item.selected) return sum + item.price;

    return sum;
  }, 0);
};

export const removeSubscriptionCardsBordersAndCounter = () => {
  // Reset borders around cards
  const selectedAboElements = document.querySelectorAll(".subscription-card__container--selected");
  selectedAboElements.forEach((item) => {
    item.classList.remove("subscription-card__container--selected");
  });

  // Reset counters on cards
  const subAboCountElements = document.querySelectorAll(".subscription-card__count");
  subAboCountElements.forEach((item) => {
    item.textContent = "";
    item.classList.remove("subscription-card__count--active");
  });
};

export const countSubscriptionCardFromShoppingCart = (subElement: Element) => {
  const subscriptionCountElement = subElement?.querySelector(".subscription-card__count");
  subscriptionCountElement.textContent = (parseInt(subscriptionCountElement.textContent || "0", 10) + 1).toString();
};

export const highlightSubscriptionCardsFromCart = (subscriptionIds: SubscriptionIds[]) => {
  try {
    removeSubscriptionCardsBordersAndCounter();

    subscriptionIds.forEach((sub) => {
      const subscriptionElements = document.querySelectorAll(`[name=subcard-${sub.blockId}]`);

      subscriptionElements?.forEach((subElement) => {
        const subscriptionCountElement = subElement?.querySelector(".subscription-card__count");
        if (!subscriptionElements || !subscriptionCountElement) return;

        subElement.classList.add("subscription-card__container--selected");
        subscriptionCountElement.classList.add("subscription-card__count--active");

        countSubscriptionCardFromShoppingCart(subElement);
      });
    });
  } catch (error) {
    console.error("An error occurred while highlighting subscription cards:", error);
  }
};

export const getSubIds = (offerings: Offering[]) => {
  return (offerings as Subscription[]).map((sub) => {
    return {
      planId: sub.planId,
      blockId: sub.blockId,
      productPage: sub.productPage,
      serviceIds: sub.additionalServices
        .filter((s) => s.selected)
        .flatMap((s) => {
          if (s.serviceId) return s.serviceId;
        }),
    };
  });
};

export const showHeader = () => {
  window.dispatchEvent(new Event("scroll"));
};

export const RemoveSpaces = (text: string) => {
  return text.replace(" ", "_");
};

// Dispatches an event to show feedback if all criterias are met
export const enableFeedbackPopup = (openButton?: HTMLElement) => {
  document.dispatchEvent(
    new MessageEvent(ICE_FEEDBACK_EVENT_KEY, {
      data: { showFeedback: true, openButton: openButton },
    }),
  );
};
