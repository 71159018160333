import { FunctionalComponent, h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { SHOPPING_CART_EVENTS } from "./shopping-cart-constants";
import { Offering } from "../shop-types";
import { isEmptyString } from "@lyse-as/ice-style/ts/00-atoms/00-global/Utils";

export const ShoppingCartNotification: FunctionalComponent = () => {
  const [subscriptionName, setSubscriptionName] = useState<string>("");
  const [routerName, setRouterName] = useState<string>("");
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const eventListener = (e: CustomEvent<Offering>) => {
      if (timeout.current !== undefined) clearTimeout(timeout.current);

      if (e.detail.offeringType === "Voice") {
        const product = e.detail;
        setSubscriptionName(product.name);
      } else {
        const mbbProduct = e.detail;

        setSubscriptionName(mbbProduct.name);
        setRouterName(mbbProduct.routername);
      }

      timeout.current = setTimeout(() => {
        setSubscriptionName("");
        timeout.current = undefined;
      }, 3000);
    };

    document.addEventListener(SHOPPING_CART_EVENTS.ADD_PRODUCT_NOTIFICATION, eventListener);

    return () => {
      document.removeEventListener(SHOPPING_CART_EVENTS.ADD_PRODUCT_NOTIFICATION, eventListener);
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    subscriptionName !== "" && (
      <div className="shopping-cart__notification">
        <div className="shopping-cart__notification__circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 48 48"
            focusable="false"
            aria-hidden="true"
          >
            <path
              fill="currentColor"
              d="M18.98 36.026L41.35 8.063l2.342 1.874-24.156 30.195L4.121 28.976l1.758-2.43z"
            ></path>
          </svg>
        </div>
        <span className="shopping-cart__notification__text">
          <strong>
            {subscriptionName}
            {isEmptyString(routerName) ? "" : `, ${routerName}`}{" "}
          </strong>{" "}
          er lagt i handlekurven
        </span>
      </div>
    )
  );
};
