import { FunctionalComponent, h } from "preact";
import { isInteger } from "../../Utils";

interface PriceFormatProps {
  amount: number | null;
  useKr?: boolean;
}

export const Price: FunctionalComponent<PriceFormatProps> = ({ amount = 0, useKr = false }: PriceFormatProps) => {
  const checkedAmount = amount ?? 0;

  const ending = useKr ? " kr" : ",&ndash;";

  return isInteger(checkedAmount) ? (
    <span>
      {checkedAmount}
      <span dangerouslySetInnerHTML={{ __html: ending }}></span>
    </span>
  ) : (
    <span>{checkedAmount.toFixed(2)}</span>
  );
};
