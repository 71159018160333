import { type MutableRef, useEffect } from "preact/hooks";
import { trapTabHandler } from "@shared/Hooks/traptabHandler";
import { keyDownHandler } from "@shared/Utils/keydown-helpers";

export type RemoveHandler = (event: Event) => void;

export const clickedOutsideShoppingCartHandler = (openBurgerState: boolean, closeShoppingCart: () => void) => {
  const clickedOutSideMenuHandler = (event: MouseEvent) => {
    if (!openBurgerState || !event.target) {
      return;
    }

    const target = event.target as HTMLElement;
    const dropDown = target.closest(".shopping-cart");
    const shoppingBurger = target.closest(".shopping-cart__burger");
    const additionalServicesDialog = target.closest("#ice-modal-container");
    const radixDialog = target.closest(".addservice-dialog");

    if (shoppingBurger || dropDown || additionalServicesDialog || radixDialog) return;

    closeShoppingCart();
  };

  useEffect(() => {
    document.addEventListener("click", clickedOutSideMenuHandler);
    return () => {
      document.removeEventListener("click", clickedOutSideMenuHandler);
    };
  }, [openBurgerState]);
};

export const useEffectKeyDownCloseHandler = (
  containerRef: MutableRef<HTMLDivElement>,
  closeHandler: (event: Event) => void,
) => {
  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.addEventListener("keydown", keyDownHandler(containerRef, closeHandler));
      document.body.classList.add("user-is-tabbing");
    }

    return () => {
      if (containerRef?.current) {
        containerRef.current.removeEventListener("keydown", keyDownHandler(containerRef, closeHandler));
      }
    };
  }, [containerRef]);
};

export const useEffectHeadingFocusHandler = (headingRef: MutableRef<HTMLDivElement>) => {
  useEffect(() => {
    if (headingRef?.current) {
      headingRef.current.focus();
    }
  }, [headingRef]);
};

export const useTrapTabHandler = (shoppingCardRef: HTMLElement) => {
  if (!shoppingCardRef) return null;

  const firstItem = shoppingCardRef.querySelector<HTMLElement>("button:first-of-type");
  if (!firstItem) return null;

  let lastItem = shoppingCardRef.querySelector<HTMLElement>("a:last-of-type");
  if (!lastItem) {
    lastItem = firstItem;
  }

  return trapTabHandler(firstItem, lastItem);
};
