import { h, Fragment, type ComponentChild, toChildArray, type VNode, type Attributes, type JSX } from "preact";
import { useRef } from "preact/hooks";

export type ToolTipProps = {
  text: string;
  buttonId: string;
  children: ComponentChild;
  isWebcomponent?: boolean;
  delay?: number;
};

interface tooltipAttributes extends Attributes {
  id: string;
}

const getChildArray = (children: ComponentChild, isWebcomponent: boolean) => {
  const childArray = toChildArray(children);

  if (isWebcomponent) {
    const funcArray = (childArray[0] as VNode<tooltipAttributes>).props.children;
    return toChildArray(funcArray);
  }

  return childArray;
};

/**
 * @param text - The text inside tooltip.
 * @param buttonId - The Id for the trigger button.
 * @param children - children elements.
 * @returns - the children and the tooltip element.
 */

export const HeaderToolTip = ({ text, buttonId, children, isWebcomponent = false }: ToolTipProps) => {
  const textref = useRef<HTMLDivElement>();

  type ButtonProps = JSX.IntrinsicElements["button"] & { className?: string; class?: string };

  const mergeClassNames = (child: VNode<ButtonProps>, appendedClassName: string, isWebComponent: boolean) => {
    const classNames = isWebComponent ? child.props.class : child.props.className;

    return classNames ? `${classNames} ${appendedClassName}` : appendedClassName;
  };

  return (
    <Fragment>
      {getChildArray(children, isWebcomponent).map((child) => {
        if (
          typeof child === "object" &&
          child.type === "button" &&
          (child as VNode<tooltipAttributes>).props.id === buttonId
        ) {
          return (
            <button
              {...child.props}
              className={mergeClassNames(child, "header-tooltip--trigger", isWebcomponent)}
              key="buttonKey"
              type="button"
            >
              {child.props.children}
            </button>
          );
        }
        return child;
      })}
      <div className="header-tooltip__text" aria-hidden="true" ref={textref}>
        {text}
      </div>
    </Fragment>
  );
};
