import { Fragment, FunctionalComponent, h } from "preact";
import { Price } from "@shared/Components";
import { getCheckoutUrlMBB, getOrderFormWebCheckoutUrl, getSubIds } from "../Helpers/shopping-cart-utils";
import SubscriptionView from "./subscription-view";
import { MbbBundle, MbbPrices, Offering, Subscription, voiceShopStoreStateType } from "../shop-types";
import { useEffect, useState } from "preact/hooks";
import { MbbSubscriptionView } from "./mbb-subscription-view";

type CheckoutBtnProps = {
  url: string;
};

const CheckoutBtn: FunctionalComponent<CheckoutBtnProps> = ({ url }: CheckoutBtnProps) => {
  return (
    <a className={"btn btn--primary"} href={url}>
      Til bestilling
    </a>
  );
};

type SubscriptionListViewProps = {
  removeOffering: (id: string) => void;
  removeService: (id: string, serviceName: string) => void;
  offerings: Offering[];
  totalSum: number;
  useVoiceStore: voiceShopStoreStateType;
};

export const OfferingsListView: FunctionalComponent<SubscriptionListViewProps> = ({
  removeOffering,
  removeService,
  offerings,
  totalSum,
  useVoiceStore,
}: SubscriptionListViewProps) => {
  const isMbb = useVoiceStore((state) => state.isMbb);

  return (
    <div className="subscriptions">
      <ul className="subscriptions__list">
        {!isMbb() ? (
          offerings.map((sub) => (
            <SubscriptionView
              key={sub.offeringId}
              sub={sub as Subscription}
              removeOffering={removeOffering}
              removeService={removeService}
              useVoiceStore={useVoiceStore}
            />
          ))
        ) : (
          <MBBFilteredSubscriptionView
            offerings={offerings}
            removeOffering={removeOffering}
            useVoiceStore={useVoiceStore}
          />
        )}
      </ul>
      {!isMbb() ? (
        <VoiceSummaryView offerings={offerings} totalSum={totalSum} />
      ) : (
        <MbbSummaryView offerings={offerings} useVoiceStore={useVoiceStore} />
      )}
    </div>
  );
};

type MBBOfferingsListViewProps = {
  removeOffering: (id: string) => void;
  offerings: Offering[];
  useVoiceStore: voiceShopStoreStateType;
};

export const MBBFilteredSubscriptionView: FunctionalComponent<MBBOfferingsListViewProps> = ({
  removeOffering,
  offerings,
  useVoiceStore,
}: MBBOfferingsListViewProps) => {
  const [filteredOfferings, setFilteredOfferings] = useState<Offering[]>(null);
  const getOfferings = useVoiceStore((state) => state.getOfferings);

  useEffect(() => {
    setFilteredOfferings(getOfferings());
  }, [offerings]);

  return (
    <Fragment>
      {filteredOfferings?.map((sub) => (
        <MbbSubscriptionView key={sub.offeringId} sub={sub as MbbBundle} removeOffering={removeOffering} />
      ))}
    </Fragment>
  );
};

type VoiceSummaryProps = {
  offerings: Offering[];
  totalSum: number;
};

export const VoiceSummaryView: FunctionalComponent<VoiceSummaryProps> = ({
  offerings,
  totalSum,
}: VoiceSummaryProps) => {
  return (
    <div className="price-summary">
      <div className="flex flex--split font-size-18 line-height--137">
        <span className="text--bold-700">Total pris</span>
        <span aria-hidden="true">
          <Price amount={totalSum} useKr={true} />
          &nbsp;/mnd
        </span>
        <span className="visually-hidden">{totalSum} kroner.</span>
      </div>
      <div className={"subscriptions__order-btn mt-1"}>
        <CheckoutBtn url={getOrderFormWebCheckoutUrl(getSubIds(offerings))} />
      </div>
    </div>
  );
};

interface MbbSummaryProps {
  offerings: Offering[];
  useVoiceStore: voiceShopStoreStateType;
}

export const MbbSummaryView: FunctionalComponent<MbbSummaryProps> = ({ offerings, useVoiceStore }: MbbSummaryProps) => {
  const getPrices = useVoiceStore((state) => state.getPrice);
  const [mbbSum, setMbbSum] = useState<MbbPrices>(getPrices() as MbbPrices);

  useEffect(() => {
    setMbbSum(getPrices() as MbbPrices);
  }, [offerings]);

  return (
    <div className="price-summary">
      <div className="font-size-18  flex flex--split line-height--137">
        <span className="text--bold-700">Total pris per måned:</span>
        <span className="" aria-hidden="true">
          <Price amount={mbbSum.total} useKr={true} />
          &nbsp;/mnd
        </span>
        <span className="visually-hidden">{mbbSum.total} kroner.</span>
      </div>
      {mbbSum.totalAfterCampaign > 0 && mbbSum.totalAfterCampaign !== mbbSum.total && (
        <div className="flex flex--split line-height--170">
          <span className="">*Pris etter kampanjeperiode:</span>
          <span className="" aria-hidden="true">
            <Price amount={mbbSum.totalAfterCampaign} useKr={true} />
            &nbsp;/mnd
          </span>
          <span className="visually-hidden">{mbbSum.total} kroner per måned.</span>
        </div>
      )}

      <div className="flex flex--split line-height--170">
        <span>Frakt:</span>
        <span aria-hidden="true">
          <Price amount={mbbSum.shippingPrice} useKr={true} />
        </span>
        <span className="visually-hidden">{mbbSum.shippingPrice} kroner.</span>
      </div>
      <div className="flex flex--split line-height--170">
        <span>Total å betale nå:</span>
        <span aria-hidden="true">
          <Price amount={mbbSum.totalUpfront} useKr={true} />
        </span>
        <span className="visually-hidden">{mbbSum.totalUpfront} kroner.</span>
      </div>
      <div className={"subscriptions__order-btn mt-1"}>
        <CheckoutBtn url={getCheckoutUrlMBB(offerings)} />
      </div>
    </div>
  );
};
