import { h, JSX } from "preact";

export const ShoppingCartIcon = (): JSX.Element => (
  <svg focusable="false" aria-hidden="true">
    <use xlinkHref="#ice-shopping-bag"></use>
  </svg>
);

export const CloseIcon = (): JSX.Element => (
  <svg focusable="false" aria-hidden="true">
    <use xlinkHref="#ice-close"></use>
  </svg>
);

export const DeleteIcon = (): JSX.Element => (
  <svg focusable="false" aria-hidden="true">
    <use xlinkHref="#ice-delete"></use>
  </svg>
);

export const TrashIcon = (): JSX.Element => (
  <svg focusable="false" aria-hidden="true">
    <use xlinkHref="#ice-trash"></use>
  </svg>
);

export const EditIcon = (): JSX.Element => (
  <svg focusable="false" aria-hidden="true">
    <use xlinkHref="#ice-edit"></use>
  </svg>
);

//Todo Tea; Update
export const ChevronUpIcon = (): JSX.Element => (
  <svg style="width:4.8em;height:4.8em" viewBox="0 0 48 48" aria-hidden="true">
    <path fill="CurrentColor" d="M45.25 31.865l1.5-2.598L24 16.133 1.25 29.266l1.5 2.6L24 19.595z" />
  </svg>
);

export const SimIcon = (): JSX.Element => (
  <svg style="width:4.8em;height:4.8em" viewBox="0 0 48 48" aria-hidden="true">
    <path
      fill="CurrentColor"
      fillRule="evenodd"
      d="M16 38V25h16v13H16zm3-3h10v-7H19v7zm16 6V17.09L24.4 7H13v34h22zm-25 3V4h15.6L38 15.803V44H10z"
    />
  </svg>
);
