import { StorageProvider } from "@shared/storage";

export interface GAProduct {
  id: string;
  name: string;
  variant: string;
  price: number;
  category: string;
  brand: string;
  dimension5: "B2C" | "B2B"; // Dimension5, business unit. "B2C" eller "B2B"
  dimension10: "Mobilt Bredbånd" | "Mobilabonnement";
  dimension18: string;
  item_category2: string;
}

interface GAProductWithOfferingIdAliases extends GAProduct {
  offeringIdAliases?: string[];
}

export interface ResponseError {
  errors: { message: string }[];
}

export interface GAProductsWithExpiry {
  timestamp: number;
  products: GAProduct[];
}

type GAProductsResponse = GAProductWithOfferingIdAliases[] | ResponseError;

export default class EcommerceAnalyticsRepository {
  private _productList = new Map<string, GAProduct>();
  private _productListKey = "ice-tracking-products";
  private _TTL = 1000 * 60 * 5;
  private _storage: StorageProvider;

  constructor(storage: StorageProvider) {
    this._storage = storage;
  }

  public getProducts = async (productIds: string[]): Promise<GAProduct[]> => {
    if (!this._productList.size) {
      await this.repopulateProductList();
    }

    return this.toProducts(productIds);
  };

  getProductIdFromAlias = async (alias: string): Promise<string> => {
    if (!this._productList.size) {
      await this.repopulateProductList();
    }

    const product = this.getProduct(alias);
    return product ? product.id : alias;
  };

  private repopulateProductList = async (): Promise<void> => {
    const cachedProducts = this._storage.load<GAProductsWithExpiry>(this._productListKey);
    if (!cachedProducts.success) {
      console.warn("Error loading GA Products from storage");
      return;
    }

    if (!cachedProducts.value || this.hasExpired(cachedProducts.value)) {
      const productListRes = await fetch("/api/analytics/products/");

      const serializedProductList = (await productListRes.json()) as string;
      const productList = JSON.parse(serializedProductList) as GAProductsResponse;
      if ("errors" in productList) {
        console.warn(productList.errors.map((e) => e.message).join(" - "));
        return;
      }
      this.populateProductList(productList);
      this.cacheProductList(productList);
    } else {
      this.populateProductList(cachedProducts.value.products);
    }
  };

  private toProducts = (productIds: string[]): GAProduct[] =>
    productIds.reduce((products: GAProduct[], productId) => {
      const product = this.getProduct(productId);
      return product ? [...products, product] : products;
    }, []);

  private getProduct = (productId: string) => {
    const product = this._productList.get(productId?.toLowerCase());
    if (!product) {
      console.warn(`OfferingId ${productId} not found for tracking.`);
    } else {
      return product;
    }
  };

  private hasExpired(cachedProducts: GAProductsWithExpiry) {
    return new Date().getTime() > cachedProducts.timestamp + this._TTL;
  }

  private populateProductList(productList: GAProductWithOfferingIdAliases[]) {
    this._productList.clear();
    productList.forEach(({ offeringIdAliases, ...product }) => {
      this._productList.set(product.id.toLowerCase(), product);
      if (offeringIdAliases) offeringIdAliases.forEach((id) => this._productList.set(id.toLowerCase(), product));
    });
  }

  private cacheProductList(productList: GAProduct[]) {
    const timestamp = new Date().getTime();
    const listWithExpiry: GAProductsWithExpiry = { products: productList, timestamp };
    this._storage.save(this._productListKey, listWithExpiry);
  }
}
