﻿import { type ComponentChildren, type FunctionalComponent, h } from "preact";
import { useState } from "preact/hooks";
import { IceFeedbackForm, IceFeedbackPopupFormSuccess } from "./components/form";
import { toBoolean } from "@shared/Utils";
import { IceFeedbackPopup } from "../Popup/ice-feedback-popup";
import { IceFeedbackPrompt } from "./components/prompt";

export const ICE_FEEDBACK_EVENT_KEY = "ice_feedback";

export interface IFeedbackEvent extends MessageEvent {
  data: {
    showFeedback: boolean;
    openButton: HTMLElement;
  };
}

export enum IceFeedbackFormSteps {
  prompt = 1,
  details = 2,
  success = 3,
}

type IceFeedbackProps = {
  children: ComponentChildren;
  show: boolean;
  appearance: "popup";
  style: string;
};

export const IceFeedback: FunctionalComponent<IceFeedbackProps> = ({
  appearance,
  style: styleClass,
  show,
}: IceFeedbackProps) => {
  const showPopupComponent = appearance === "popup";

  if (showPopupComponent) return <IceFeedbackPopup show={toBoolean(show)} />;

  const [feedbackFormStep, setFeedbackFormStep] = useState<IceFeedbackFormSteps>(IceFeedbackFormSteps.prompt);
  const [userFeedbackPositive, setUserFeedbackPositive] = useState<boolean | undefined>(undefined);

  const renderContent = () => {
    switch (feedbackFormStep) {
      case IceFeedbackFormSteps.prompt:
        return (
          <IceFeedbackPrompt
            feedbackFormStep={feedbackFormStep}
            setFeedbackFormStep={setFeedbackFormStep}
            setUserFeedbackPositive={setUserFeedbackPositive}
          />
        );
      case IceFeedbackFormSteps.details:
        return (
          <IceFeedbackForm
            userOpinionState={userFeedbackPositive}
            feedbackFormStep={feedbackFormStep}
            setFeedbackFormStep={setFeedbackFormStep}
          />
        );
      case IceFeedbackFormSteps.success:
        return <IceFeedbackPopupFormSuccess />;
      default:
        return null;
    }
  };
  const additionalStyleClasses = styleClass !== undefined ? `ice-feedback--${styleClass?.toLowerCase()}` : "";

  return (
    <div className={`ice-feedback ${additionalStyleClasses}`}>
      <div className="ice-feedback__content">
        <div className="container">{renderContent()}</div>
      </div>
    </div>
  );
};
