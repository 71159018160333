import { FunctionalComponent, Fragment, h } from "preact";
import { IceSvgByName } from "./form";
import { IceFeedbackFormSteps } from "../ice-feedback-component";
import { sendFeedbackAnalyticsEventForComponent } from "@shared/Analytics/content/feedback-analytics";

interface IceFeedbackPromptProps {
  feedbackFormStep: IceFeedbackFormSteps;
  setFeedbackFormStep: (setFeedbackStep: number) => void;
  setUserFeedbackPositive: (setUserFeedbackPositive: boolean) => void;
}

export const IceFeedbackPrompt: FunctionalComponent<IceFeedbackPromptProps> = ({
  feedbackFormStep,
  setFeedbackFormStep,
  setUserFeedbackPositive,
}: IceFeedbackPromptProps) => {
  return (
    <Fragment>
      <div className="ice-feedback__title ice-feedback__title--text-center">Fant du det du lette etter?</div>
      <div className="ice-feedback__block">
        <button
          className={`btn--feedback btn-feedback__component`}
          onClick={() => {
            setFeedbackFormStep(feedbackFormStep + 1);
            setUserFeedbackPositive(true);
            sendFeedbackAnalyticsEventForComponent("ja_send", "feedback_question_1");
          }}
        >
          <IceSvgByName name={"ice-thumbs-up"} />
          Ja
        </button>
        <button
          className={`btn--feedback btn-feedback__component`}
          onClick={() => {
            setFeedbackFormStep(feedbackFormStep + 1);
            setUserFeedbackPositive(false);
            sendFeedbackAnalyticsEventForComponent("nei_send", "feedback_question_1");
          }}
        >
          <IceSvgByName name={"ice-thumbs-down"} />
          Nei
        </button>
      </div>
    </Fragment>
  );
};
