export const trapTabHandler = (firstRef: HTMLElement, lastRef: HTMLElement) => {
  const first = firstRef;
  const last = lastRef;

  const TrapTabEventHandler = (e: KeyboardEvent): void => {
    const isTabPressed = e.key === "Tab" || e.keyCode === 9;
    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) {
      if (document.activeElement === first) {
        last.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === last) {
        first.focus();
        e.preventDefault();
      }
    }
  };

  document.addEventListener("keydown", TrapTabEventHandler);
  return (): void => {
    document.removeEventListener("keydown", TrapTabEventHandler);
  };
};
