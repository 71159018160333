import { uuid } from "@shared/Utils";
import { h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";

export interface expandableModel {
  contentingress: string;
  content: string;
  readmoretext: string;
  closetext: string;
  ariabuttonText: string;
}

class TransitionState {
  _use = false;
  get checkTransition() {
    return this._use;
  }
  set checkTransition(value: boolean) {
    this._use = value;
  }
}

export const ServiceExpandable = ({
  contentingress,
  content,
  readmoretext,
  closetext,
  ariabuttonText,
}: expandableModel): h.JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState<boolean>(true);

  const checkTransition: TransitionState = new TransitionState();

  const containerHiddenClass = "service-expandable__container--hidden";
  const contentHiddenClass = "service-expandable__content--hidden";

  const expandCollaps = () => {
    const container = containerRef.current;
    const content = contentRef.current;
    if (open === false) {
      setOpen(true);
      setTimeout(() => {
        container.classList.remove(containerHiddenClass);
        content.classList.remove(contentHiddenClass);
      });
    } else {
      checkTransition.checkTransition = true;
      containerRef.current.addEventListener("transitionend", transitionEndHandler);
      contentRef.current.addEventListener("transitionend", transitionEndHandler);
      container.classList.add(containerHiddenClass);
      content.classList.add(contentHiddenClass);
    }
  };

  const setElementHeight = (item: HTMLElement) => {
    item.style.height = `${item.offsetHeight / 16}rem`;
  };

  const setInitHidden = (item: HTMLElement, hiddenclass: string) => {
    item.classList.add(hiddenclass);

    setTimeout(() => {
      item.classList.add("service-expandable--transition");
    }, 50);
  };

  const transitionEndHandler = (e: Event) => {
    const el = e.target as HTMLElement;

    if (checkTransition.checkTransition === true) {
      setOpen(false);
      checkTransition.checkTransition = false;

      let parent = el.parentElement;
      if (parent.classList.contains("service-expandable__el")) {
        parent = parent.parentElement;
      }

      parent.querySelectorAll(".service-expandable__el").forEach((item) => {
        item.removeEventListener("transitionend", transitionEndHandler);
      });
    }
  };

  useEffect(() => {
    const container = containerRef?.current;
    const content = contentRef?.current;
    if (container) {
      setElementHeight(container);
      setElementHeight(content);
      setInitHidden(container, containerHiddenClass);
      setInitHidden(content, contentHiddenClass);
      setTimeout(() => {
        setOpen(false);
      });
    }
  }, []);

  const contentId = uuid();
  return (
    <div className="service-expandable">
      <div ref={containerRef} className={`service-expandable__el service-expandable__container`} role="alert">
        <div>{contentingress}</div>
        <div ref={contentRef} className={`service-expandable__el service-expandable__content`}>
          {open && (
            <div>
              <span dangerouslySetInnerHTML={{ __html: content }}></span>
            </div>
          )}
        </div>
      </div>

      <button
        type="button"
        className="flex flex--split service-expandable__readmore link disable-text-decoration link--hoverable"
        onClick={expandCollaps}
        aria-describedby={contentId}
      >
        {open ? closetext : readmoretext}
        <svg
          className={`service-expandable__readmore__icon ${open ? "service-expandable__readmore__icon--open" : ""}`}
          focusable="false"
          aria-hidden="true"
        >
          <use xlinkHref="#ice-chevron--down"></use>
        </svg>
        <span className="visually-hidden">{ariabuttonText}</span>
      </button>
    </div>
  );
};
